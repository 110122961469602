import React from 'react';
import Layout from '../components/Layout';
import * as FaIcons from 'react-icons/fa';
import { GrGraphQl } from "react-icons/gr";
import oc from '../images/SoftwareDeveloper_Peter_Adkins.png';

const Skills = () => {
    return (
        <Layout>
            <React.Fragment>
                <section className="mySkills mt-5">
                    <div className="skillSet mt-4">
                        <span className="mr-1"><FaIcons.FaHtml5 className="logoHover" size={50} /></span>
                        <span className="mr-1"><FaIcons.FaCss3Alt className="logoHover" size={50} /></span>
                        <span className="mr-1"><FaIcons.FaJsSquare className="logoHover" size={50} /></span>
                        <span className="mr-1"><FaIcons.FaDatabase className="logoHover" size={50} /></span>
                        <span className="mr-1"><FaIcons.FaReact className="logoHover" size={50} /></span>
                        <span className="mr-1"><FaIcons.FaSass className="logoHover" size={50} /></span>
                        <span className="mr-1"><FaIcons.FaWordpress className="logoHover" size={50} /></span>
                        <span className="mr-1 logoHover"><GrGraphQl className="logoHover" size={50} /></span>
                    </div>
                    <div className="mt-4">
                        <img className="openClass" alt="Openclassrooms certificate" src={oc} />
                    </div>
                </section>
          </React.Fragment>
        </Layout>
    )
}

export default Skills